$font-size-base:              1rem;
$font-size-14:                $font-size-base * 0.875;
$font-size-16:                $font-size-base;
$font-size-18:                $font-size-base * 1.125;
$font-size-20:                $font-size-base * 1.25;
$font-size-24:                $font-size-base * 1.5;
$font-size-32:                $font-size-base * 2;
$font-size-50:                $font-size-base * 3.125;
$font-size-60:                $font-size-base * 3.75;
$font-size-70:                $font-size-base * 4.375;

$border-radius:               0;
$border-radius-lg:            0;
$border-radius-sm:            0;

$darkblue:        #084480;
$blue:            #0267AB;
$lightblue:       #E5EFF8;
$yellow:          #FE9900;
$white:           #FFF;
$dark:            #333333;
$grey:            #777777;
$light:           #F4F4F4;
