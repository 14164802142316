@import '../../variables';

.box {
  padding: 0 3rem;
  white-space: pre-line;
}

.box p {
  font-weight: 600;
  font-size: $font-size-18;
  color: $dark;
}
