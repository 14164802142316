@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600&display=swap');

$font-family-sans-serif:      'Source Sans Pro', sans-serif;

$border-radius:               0;
$border-radius-lg:            0;
$border-radius-sm:            0;

$darkblue:        #084480;
$blue:            #0267AB;
$lightblue:       #E5EFF8;
$yellow:          #FE9900;
$white:           #FFF;
$dark:            #333333;
$grey:            #777777;
$light:           #F4F4F4;

.custom-checkbox .custom-control-input~ .custom-control-label::before {
  border: 2px solid $yellow;
  width: 18px;
  height: 18px;
}
.custom-checkbox .custom-control-input~ .custom-control-label::after {
  border: 2px solid $yellow;
  width: 18px;
  height: 18px;
}
.custom-checkbox .custom-control-label {
  vertical-align: middle;
}

/*.carousel-control-prev,
.carousel-control-next {
  @media screen and (max-width: 991px) {
    top: 230px !important;
    bottom: auto !important;
  }
  @media screen and (max-width: 767px) {
    top: 170px !important;
    bottom: auto !important;
  }
  @media screen and (max-width: 479px) {
    top: 100px !important;
    bottom: auto !important;
  }
}*/

.carousel-control-prev {
  left: unset !important;
  right: 68px !important;
  width: 67px !important;
  opacity: 0 !important;
}
.carousel-control-next {
  width: 67px !important;
  opacity: 0 !important;
}

.error {
  color: red;
}

@import '~bootstrap/scss/bootstrap.scss';
